import { stringify } from 'qs';

const territories = [
  'Atlanta, GA',
  'Boston, MA',
  'Chicago, IL',
  'Dallas - Fort Worth, TX',
  'Detroit, MI',
  'Houston, TX',
  'Los Angeles, CA',
  'Miami, FL',
  'New York, NY',
  'Philadelphia, PA',
  'Phoenix, AZ',
  'San Diego, CA',
  'San Francisco, CA',
  'Seattle, WA',
  'Washington DC'
];

export const POPULAR_AREAS = territories.map(name => ({
  name,
  url: `/shelters#locate=${stringify({ query: { location: name } })}`
}));
