import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'qs';

import { POPULAR_AREAS } from 'dpl/shared/constants/shelters';
import {
  FancyDropdownMenu,
  FancyDropdownMenuItem
} from 'dpl/components/FancyDropdown';
import SearchNoResults from 'dpl/components/Search/SearchNoResults';

const DEFAULT_DISTANCE = 50;
function coordinatesToParams(coords) {
  return window.encodeURIComponent(
    stringify({
      query: {
        coordinates: {
          formatted_address: coords.displayName,
          distance: DEFAULT_DISTANCE,
          latitude: coords.lat,
          longitude: coords.lng
        }
      }
    })
  );
}

export function ShelterResultsResultGoogleItem({ place }) {
  return (
    <a
      href="javascript:void(0);"
      className="w-100 pv2 ph2 db"
      onClick={() =>
        place.getCoordinates().then(coord => {
          window.location = `/shelters#locate=${coordinatesToParams(coord)}`;
        })
      }
    >
      {place.name}
    </a>
  );
}

ShelterResultsResultGoogleItem.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string.isRequired,
    getCoordinates: PropTypes.func.isRequired
  }).isRequired
};

export function ShelterResultsResultAreaItem({ area }) {
  return (
    <a className="w-100 pv2 ph2 db" href={area.url}>
      {area.name}
    </a>
  );
}

ShelterResultsResultAreaItem.propTypes = {
  area: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default function ShelterResultsResultsList({
  googlePlacesResults,
  query
}) {
  return (
    <FancyDropdownMenu className="ShelterSearchResultsList overflow-y-auto br2 box-shadow-2 bg-white mt2 ph2 pv2 ba b--light-gray">
      {(() => {
        if (!query) {
          return (
            <Fragment>
              <p className="silver fw-medium mb2">Popular areas</p>
              {POPULAR_AREAS.map(area => (
                <FancyDropdownMenuItem key={area.url} itemKey={area.url}>
                  <ShelterResultsResultAreaItem area={area} />
                </FancyDropdownMenuItem>
              ))}
            </Fragment>
          );
        }

        return googlePlacesResults.length === 0 ? (
          <SearchNoResults query={query} />
        ) : (
          googlePlacesResults.map((place, idx) => (
            <FancyDropdownMenuItem key={idx} itemKey={idx}>
              <ShelterResultsResultGoogleItem place={place} />
            </FancyDropdownMenuItem>
          ))
        );
      })()}
    </FancyDropdownMenu>
  );
}

ShelterResultsResultsList.propTypes = {
  query: PropTypes.string,
  googlePlacesResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      getCoordinates: PropTypes.func.isRequired
    })
  ).isRequired
};

ShelterResultsResultsList.defaultProps = {
  query: null
};
