import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';
import { FancyDropdownMenuItem } from 'dpl/components/FancyDropdown';

export default function BreedSearchResultItem({
  coverPhotoUrl,
  inDropdown,
  live,
  name,
  url
}) {
  const content = (
    <a
      href={url}
      className="BreedSearchResultItem db w-100 flex items-center ph2-md pv2"
    >
      {coverPhotoUrl && live && (
        <div className="f0 mr6">
          <SmartImage
            lazy
            crop
            width="64px"
            height="64px"
            loadingClass="bg-light-gray"
            className="br1"
            src={coverPhotoUrl}
          />
        </div>
      )}
      <span className="truncate flex-auto fw-normal-ns fw-medium">{name}</span>
      {!live && (
        <div className="flex items-center f2">
          <span className="i fw-light light-silver nowrap">Coming soon</span>
        </div>
      )}
    </a>
  );

  if (!inDropdown) {
    return content;
  }

  return (
    <FancyDropdownMenuItem itemKey={url} selectedClassName="bg-near-white">
      {content}
    </FancyDropdownMenuItem>
  );
}

BreedSearchResultItem.propTypes = {
  coverPhotoUrl: PropTypes.string,
  inDropdown: PropTypes.bool.isRequired,
  live: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

BreedSearchResultItem.defaultProps = {
  coverPhotoUrl: null
};
