import React from 'react';
import PropTypes from 'prop-types';

export default function SearchNoResults({ query }) {
  if (!query) {
    return null;
  }

  return (
    <span className="moon-gray pv4 ph4 db truncate">
      No results matching &ldquo;{query}&rdquo;
    </span>
  );
}

SearchNoResults.propTypes = {
  query: PropTypes.string.isRequired
};
