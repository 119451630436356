import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BreedSearchResultPropTypes } from 'dpl/shared/utils/customPropTypes';
import { FancyDropdownMenu } from 'dpl/components/FancyDropdown';
import BreedSearchResultsList from 'dpl/components/BreedSearch/BreedSearchResultsList';
import BreedSearchResultsBreedTypeLearnLink from 'dpl/components/BreedSearch/BreedSearchResultsBreedTypeLearnLink';
import LoadingWrapper from 'dpl/components/LoadingWrapper';

export default function BreedSearchResults({
  isOpen,
  breeds,
  query,
  breedType,
  isLoading
}) {
  if (!isOpen) {
    return null;
  }

  const breedTypeManagedByParent = breedType !== null;

  return (
    <FancyDropdownMenu
      className="BreedSearchResults overflow-y-auto br2 box-shadow-2 bg-white mt2 ph2 pt2 ba b--light-gray"
      contentClassName={classnames('pt2', {
        'pb6 ph3': breedTypeManagedByParent,
        ph6: !breedTypeManagedByParent
      })}
    >
      <LoadingWrapper isLoading={isLoading}>
        <BreedSearchResultsList
          inDropdown
          breeds={breeds}
          query={query}
          breedType={breedType}
        />
        {!breedTypeManagedByParent && (
          <div className="bg-near-white box-shadow-2 bottom-0 left-0 right-0 nh8 ph8 pv4 f2 sticky">
            <BreedSearchResultsBreedTypeLearnLink />
          </div>
        )}
      </LoadingWrapper>
    </FancyDropdownMenu>
  );
}

BreedSearchResults.propTypes = {
  query: PropTypes.string.isRequired,
  breeds: PropTypes.arrayOf(PropTypes.shape(BreedSearchResultPropTypes))
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  breedType: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

BreedSearchResults.defaultProps = {
  breedType: null
};
