import React from 'react';
import PropTypes from 'prop-types';

import { BreedSearchResultPropTypes } from 'dpl/shared/utils/customPropTypes';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import BreedSearchResultsList from 'dpl/components/BreedSearch/BreedSearchResultsList';
import BreedSearchResultsBreedTypeLearnLink from 'dpl/components/BreedSearch/BreedSearchResultsBreedTypeLearnLink';

export default function MobileBreedSearchResults({ breeds, query }) {
  return (
    <div className="MobileBreedSearchResults">
      <LoadingWrapper isLoading={!query && breeds.length === 0}>
        <div className="pb20">
          <BreedSearchResultsList breeds={breeds} query={query} />
          <div className="bg-white box-shadow pv2 ph6 fixed left-0 right-0 bottom-0 transform-hide-y">
            <BreedSearchResultsBreedTypeLearnLink />
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
}

MobileBreedSearchResults.propTypes = {
  query: PropTypes.string.isRequired,
  breeds: PropTypes.arrayOf(PropTypes.shape(BreedSearchResultPropTypes))
    .isRequired
};
